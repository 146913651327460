<template>
	<div class="confirm-vise">
		<div class="confirm-visea" v-if="VisaDetailInfo!=null">
			<!-- 签证详情外层盒子1 -->
			<div class="confirm-viseb">
				<!-- 签证详情外层盒子2 -->
				<div class="confirm-visec">
					<!-- 签证详情外层盒子3 -->
					<!-- <div class="confirm-vised">一起飞国际机票网>签证>古巴签证>美国旅游签证（多次）</div> -->
					<!-- 一起飞国际机票网 -->
					<div class="confirm-visee marg-but" v-if="VisaDetailInfo!=null">
						<!-- 图片文字最外层 -->
						<div class="confirm-visef">
							<!-- 图片文字外层 -->
							<div class="confirm-imgb">
								<!-- 图片 -->
								<img :src="VisaDetailInfo.VisaTypeImg" class="confirm-imga">
							</div>
							<div class="">
								<div class="confirm-viseg">{{VisaDetailInfo.VisaName}}</div>
								<!-- 美国旅游签证多次 -->
								<div class="">
									<!-- 海关 -->
									<div class="confirm-viseh">受理时间：{{VisaDetailInfo.Needday}}</div>
									<div class="confirm-visei">签证有效期：{{VisaDetailInfo.Validday}}</div>
									<div class="confirm-visei">可停留天数：{{VisaDetailInfo.Stay}}</div>
									<div class="confirm-visei">签证类型：{{VisaDetailInfo.VisaTypeName}}</div>
								</div>
							</div>
						</div>

						<div class="confirm-visez">
							<!-- 预定价格外层盒子 -->
							<!-- <div class="confirm-share">
								<div class="">分享</div>
							</div> -->
							<div class="confirm-visek">
								<!-- 价格外层盒子 -->
								<div class="confirm-visel">¥</div>
								<div class="confirm-visem">{{VisaDetailInfo.Price}}</div>
							</div>
							<div class="confirm-charge">
								<div class="" v-if="VisaDetailInfo.IncFees!=''">({{VisaDetailInfo.IncFees}})</div>
							</div>
							<div class="confirm-visenn">
								<div class="confirm-visen" style="padding: 15px 30px 15px 30px;" @click="bindNext">立即购买
								</div>
							</div>
						</div>
					</div>
					<div class="">
						<el-tabs v-model="activeName" @tab-click="handleClick" v-if="ApplyPassengerTypeList!=null">
							<!-- <el-tab-pane label="所需材料" name="first"> -->
							<div class="">
								<div class="confirm-materials">
									<div class="confirm-materialsa"></div>
									<div class="">所需材料</div>
								</div>
								<div class="confirm-materiald">
									<div class="confirm-materialc" v-for="(item,index) in ApplyPassengerTypeList"
										:key="index" @click="bindNav(index)"
										:class="[NeedSubIdx==index?'confirm-materialcc':'confirm-materialc']">
										{{item.PassengerTypeName}}
									</div>
								</div>
								<div class="" v-if="NeedSubTypes!=null">
									<div class="confirm-materialz" v-for="(item,index) in NeedSubTypes" :key="index">
										<div class="confirm-materiale">
											<!-- 选项卡选中以内的内容 -->
											<div class="confirm-materialf">{{index+1}}、</div>
											<div class="confirm-materialf confirm-materialg">{{item.SubTypeName}}</div>
											<!-- <div class="confirm-materialg confirm-materialh">复印件</div> -->
											<div class="confirm-materialh" v-if="item.IsNecessary">必备</div>
										</div>
										<div class="">
											<div class="confirm-materiali">
												{{item.Content}}
											</div>
										</div>
									</div>

									<div class="">
										<div class="confirm-materials confirm-materiali">
											<!-- 所需材料1 -->
											<div class="confirm-materialsa"></div>
											<!-- 所需材料2 -->
											<div class="confirm-materialsb">预定必知</div>
										</div>
										<div class="confirm-materialk">
											<div class="confirm-materiall">
												1.在办理签证期间, 我司可能会根据您的材料情况要求增补其他材料、担保金或予以劝退; 领馆也可能会针对您的实际情况,
												要求增补其他材料或延长受理时间（此情况由领馆决定, 我司无法干预）。
											</div>
											<div class="confirm-materiall">
												2.请您理解, 提供完整材料并不能作为获得签证的保证, 最终签证结果将由申请国领馆决定。
											</div>
											<div class="confirm-materiall">
												3.此签证类型是由我司代送签, 出签后将由我司配送给您或您前往我司领取护照。
											</div>
										</div>
										<div class="confirm-materialm"
											v-if="VisaDetailInfo!=null&&VisaDetailInfo.Alert!=''">
											<div class="confirm-materialn">温馨提示:</div>
											<div class="">
												<!-- 英国签证申请必须本人亲自岛签证中心提交材料 -->
												{{VisaDetailInfo.Alert}}
											</div>
										</div>
									</div>

									<!-- <div class="" v-for="item in [1,2,3,4,5,6,7,8,9]" :key="item">
											<div class="confirm-grade">
												
												<div class="confirm-graded">
													<div class="confirm-materialsa"></div>
													
													<div class="confirm-gradeb">用户评价</div>
												</div>
												<div class="confirm-gradea">非常棒4.9分</div>
											</div>
											<div class="">
												<div class="confirm-headl">
													<div class="confirm-head">
														
														<el-row class="">
															<el-col :span="12">
																<div class="confirm-heada">
																	<div class="confirm-headb"><el-avatar :size="50" :src="circleUrl"></el-avatar></div>
																	
																	<div class="confirm-headc">黄耀坤</div>																	
																	<div class="confirm-headd">个人旅行</div>
																	<div class="confirm-headd">情侣出行</div>
																</div>
															</el-col>
														</el-row>
													</div>
													<div class="confirm-heado">2018年8月23日</div>
												</div>
												
												<div class="confirm-score">
													
													<div class="confirm-scorea">
														<div class="confirm-scoreb">出签速度</div>
														<div class="">
															<el-rate v-model="value1" :colors="colors"></el-rate>
														</div>
													</div>
													<div class="confirm-scorea">
														<div class="confirm-scoreb">签证价格</div>
														<div class="">
															<el-rate v-model="value2" :colors="colors"></el-rate>
														</div>
													</div>
													<div class="confirm-scorea">
														<div class="confirm-scoreb">材料要求</div>
														<div class="">
															<el-rate v-model="value3" :colors="colors"></el-rate>
														</div>
													</div>
												</div>
												<div class="confirm-scoree">
													出签速度非常快, 提交材料非常方便。顾问服务非常周到。十分满意下次继续。
												</div>
												<div class="confirm-scoreeimg">
													<div class="confirm-scoreeimgb">
														<div class="confirm-scoreeimga" v-for="item in [1,2,3,4,5]" :key="item">
															<el-avatar shape="" :size="120" :src="url"></el-avatar>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="confirm-scores">
											<el-pagination 
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
											:current-page.sync="currentPage3"
											:page-size="100"
											layout="prev, pager, next, jumper"
											:total="10000">
											</el-pagination>
										</div> -->
								</div>
							</div>
							<!-- </el-tab-pane> -->
							<!-- <el-tab-pane label="行程信息" name="second">
								1
							</el-tab-pane>
							<el-tab-pane label="联系人信息" name="third">
								2
							</el-tab-pane> -->
						</el-tabs>
					</div>
				</div>

				<div class="confirm-visej">
					<div class="confirm-viseja">更多{{CountryName}}签证</div>
					<div class="confirm-visejb" v-for="item in VisaList" :key="item" @click="bindDetailByList(item)">
						<div class="confirm-visejc">
							<el-image fit="cover" class="confirm-visejd" :src="item.VisaTypeImg"></el-image>
						</div>
						<div class="confirm-viseje">
							<div class="confirm-visejf">{{item.VisaName}}</div>
							<div class="confirm-visejg">
								<div class="confirm-visejga">有效期：</div>
								<div class="confirm-visejgb">{{item.Validday}}</div>
							</div>
							<div class="confirm-visejg">
								<div class="confirm-visejga">最多停留：</div>
								<div class="confirm-visejgb">{{item.Stay}}</div>
							</div>
							<div class="confirm-visejg">
								<div class="confirm-visejga">预计工作日：</div>
								<div class="confirm-visejgb">{{item.NeedDay}}</div>
							</div>
							<!-- <div class="confirm-visejg">
								<div class="">签证类型：</div>
								<div class="">商务签证</div>
							</div> -->
							<div class="confirm-visejhi">
								<div class="confirm-visejgh">¥</div>
								<div class="confirm-visejgi">{{item.MinPrice}}</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var localdatas = require('@/utils/localdatas.js');
	var staticData = require('@/data/staticData.js');
	//接口
	var visaApi = require('@/api/visaApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;

	export default {
		data() {
			return {
				activeName: 'first',
				list: 0,
				List: ['自由职业', '学龄前儿童', '退休人员', '在校学生', '在职人员'],
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				value1: null,
				value2: null,
				value3: null,
				colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
				url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				currentPage3: 1,

				NeedSubIdx: 0,
				CountryCode: '',
				CountryName:'',
				GoodCode: '',
				VisaDetailInfo: null,
				ApplyPassengerTypeList: null,
				NeedSubTypes: null,
				VisaList: null
			};
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			if (!util.isNullOrEmpty(query.GoodCode)) {
				that.GoodCode = query.GoodCode;
				GetVisaDetailInfo();
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
			bindNav(index) {
				this.NeedSubIdx = index;
				that.NeedSubTypes = that.ApplyPassengerTypeList[index].NeedSubTypes;
				console.log(index)
			},
			bindDetailByList(e) {
				this.NeedSubIdx = 0;
				that.GoodCode = e.GoodCode;
				that.$router.push({
					path: '/Visa/detail',
					query: {
						GoodCode: e.GoodCode
					}
				})
				GetVisaDetailInfo();
			},
			bindNext: function(e) {
				localdatas.setvisaBookInfo(that.VisaDetailInfo);
				that.$router.push({
					path: '/Visa/booking'
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			}
		}
	};
	//获取签证详情
	function GetVisaDetailInfo() {
		var parm = {
			GoodCode: that.GoodCode, //洲代码
		};
		visaApi.GetVisaDetailInfo(parm, function(result) {
			if (result.code == 0) {
				that.VisaDetailInfo = result.data.VisaDetailInfo;
				that.CountryName=that.VisaDetailInfo.CountryName;
				that.ApplyPassengerTypeList = result.data.ApplyPassengerTypeList;
				if (that.ApplyPassengerTypeList != null && that.ApplyPassengerTypeList.length > 0) {
					that.NeedSubTypes = that.ApplyPassengerTypeList[0].NeedSubTypes;
				}
				else{
					that.NeedSubTypes=result.data.visaRsp.NeedSubTypes;
				}
				that.CountryCode = that.VisaDetailInfo.CountryCode;
				GetFrontVisaList();
			}

		})
	}

	function GetFrontVisaList() {
		var parm = {
			Continent: '', //洲代码
			IATACityCode: '', //城市代码
			Country: that.CountryCode, //国家代码
			VisaType: '', //签证类型
		};
		visaApi.GetFrontVisaList(parm, function(result) {
			if (result.code == 0) {
				that.VisaList = result.data.VisaList;
				// that.CountryName=that.VisaList[0].CountryName;
			}
		})
	}
</script>

<style lang="scss">
	@import '../../style/visa/confirm.scss';
</style>
